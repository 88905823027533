// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-routes-confirm-js": () => import("./../../../src/routes/confirm.js" /* webpackChunkName: "component---src-routes-confirm-js" */),
  "component---src-routes-features-js": () => import("./../../../src/routes/features.js" /* webpackChunkName: "component---src-routes-features-js" */),
  "component---src-routes-home-js": () => import("./../../../src/routes/home.js" /* webpackChunkName: "component---src-routes-home-js" */),
  "component---src-routes-join-js": () => import("./../../../src/routes/join.js" /* webpackChunkName: "component---src-routes-join-js" */),
  "component---src-routes-thank-you-js": () => import("./../../../src/routes/thank-you.js" /* webpackChunkName: "component---src-routes-thank-you-js" */)
}

