// Overrides Gatsby defaults to enable smooth scrolling into local anchors within the same page.
exports.shouldUpdateScroll = ({ prevRouterProps, routerProps }) => {
  const { hash } = routerProps.location;
  if (!hash) {
    return;
  }

  // We don't want to animate scrolling to external anchor on another page.
  if (prevRouterProps && prevRouterProps.location.pathname !== routerProps.location.pathname) {
    return true; // Default behavior without any smooth scroll.
  }

  const elementId = decodeURI(hash.slice(1));

  const element = document.getElementById(elementId);
  if (!element) {
    return;
  }

  element.scrollIntoView({ behavior: 'smooth' });

  return false;
};
